import { Box, makeStyles, Typography } from '@material-ui/core'
import { ConditionBadge } from 'components/facets/ConditionBadge'
import alteredIcon from 'img/alteredIcon.png'
import firstEdIcon from 'img/firstEdIcon.png'
import foilIcon from 'img/foilIcon.png'
import reverseHoloIcon from 'img/reverseHoloIcon.png'
import signedIcon from 'img/signedIcon.png'
import { getPlaysetCoefficient } from 'lib/utils'
import { useTranslation } from 'react-i18next'
import Flag from 'react-world-flags'
import { OrderArticle } from 'shared'
import { LANGUAGE_MAP_ICONS } from 'utils/constants'

export interface CardInfoBoxProps {
  article: OrderArticle
  fullScreen?: boolean
  transparent?: boolean
}

export const CardInfoBox = ({
  article,
  fullScreen = false,
  transparent = false,
}: CardInfoBoxProps): JSX.Element => {
  const { t } = useTranslation()
  const iconHeight = 16

  const useStyles = makeStyles(() => ({
    infoBox: {
      color: 'black',
      backgroundColor: 'white',
      opacity: transparent ? 0.8 : 1,
      padding: '5px',
      borderRadius: '5px',
    },
  }))
  const styles = useStyles()

  return (
    <>
      <Box className={styles.infoBox}>
        <Box>
          <Typography variant={fullScreen ? 'h6' : 'body2'}>
            <b>
              {article.product.locName}{' '}
              {article.product.nr && (
                <>
                  [{article.product.expansionCode}-{article.product.nr}]
                </>
              )}
            </b>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant={fullScreen ? 'h6' : 'body2'}>
            {t('orders.properties')}:{' '}
            <b>
              {article.isPlayset
                ? article.count * getPlaysetCoefficient(article.product.idGame)
                : article.count}
              x
            </b>
          </Typography>
          <Box padding="3px" />
          <Flag
            fallback={<span>Unknown</span>}
            code={LANGUAGE_MAP_ICONS[article.language.idLanguage] || 'gg'}
            height={iconHeight}
          />
          <Box padding="3px" />
          <ConditionBadge condition={article.condition} />
          {article.isFoil && <img alt="foil" src={foilIcon} height={iconHeight} />}
          {article.isAltered && <img alt="altered" src={alteredIcon} height={iconHeight} />}
          {article.isSigned && <img alt="signed" src={signedIcon} height={iconHeight} />}
          {article.isFirstEd && (
            <img
              alt="firstEd"
              height={iconHeight}
              src={firstEdIcon}
              style={{ alignSelf: 'center', marginLeft: '2px', marginRight: '2px' }}
            />
          )}
          {article.isReverseHolo && (
            <img
              alt="reverseHolo"
              height={iconHeight}
              src={reverseHoloIcon}
              style={{ alignSelf: 'center', marginLeft: '2px', marginRight: '2px' }}
            />
          )}
        </Box>
        <Typography variant={fullScreen ? 'h6' : 'body2'}>
          Picking ID: <b>{article.pickingId}</b>
        </Typography>
        <Typography variant={fullScreen ? 'h6' : 'body2'}>
          {t('card.comment')}: <b>{article.comments}</b>
        </Typography>
        <Typography variant={fullScreen ? 'h6' : 'body2'}>
          {t('card.price')}:{' '}
          <b>
            {article.price} {article.currencyCode}
          </b>
        </Typography>
      </Box>
    </>
  )
}
