import { Box } from '@material-ui/core'
import { useOrderStore } from 'lib/hooks'
import { FixedSizeList } from 'react-window'
import { OrderArticle } from 'shared'
import { OrderVisualArticle } from './OrderVisualArticle'

export interface PickingImageDisplayProps {
  type: string
  handlePick?: (article: OrderArticle, quantity: number) => void
  handleCompletePicking?: (article: OrderArticle, quantity: number) => void
  handleReportIssue?: (article: OrderArticle) => void
  handleBackToPicking?: (article: OrderArticle) => void
}

export const PickingImageDisplay = ({
  type,
  handlePick,
  handleCompletePicking,
  handleReportIssue,
  handleBackToPicking,
}: PickingImageDisplayProps): JSX.Element => {
  const articlesToPick = useOrderStore((state) => state.articlesToPick)
  const articlesPicked = useOrderStore((state) => state.articlesPicked)
  const articlesWithIssues = useOrderStore((state) => state.articlesWithIssues)

  const { innerWidth: width } = window
  const cardWidth = 212
  const spacing = 50
  const itemWidth = cardWidth + spacing

  let articles: OrderArticle[] = []

  if (type === 'toPick') {
    articles = articlesToPick
  } else if (type === 'picked') {
    articles = articlesPicked
  } else if (type === 'withIssues') {
    articles = articlesWithIssues
  }

  return (
    <FixedSizeList
      layout="horizontal"
      height={560}
      itemCount={articles.length}
      itemSize={itemWidth}
      itemData={articlesToPick}
      width={width * 0.78}
    >
      {({ index, style }: { index: number; style: any }) => {
        return (
          <Box style={style}>
            {type === 'toPick' && (
              <OrderVisualArticle
                article={articles[index]}
                handlePick={handlePick}
                handleCompletePicking={handleCompletePicking}
                handleReportIssue={handleReportIssue}
                hideBar
              />
            )}

            {type === 'picked' && (
              <OrderVisualArticle
                article={articles[index]}
                handleBackToPicking={handleBackToPicking}
                hideBar
                hideButtons
              />
            )}

            {type === 'withIssues' && (
              <OrderVisualArticle
                article={articles[index]}
                handleBackToPicking={handleBackToPicking}
                hideBar
                hideButtons
              />
            )}
          </Box>
        )
      }}
    </FixedSizeList>
  )
}
