import axios, { AxiosRequestConfig } from 'axios'
import { StatusCallParams } from 'components/facets/StatusBlock'
import pako from 'pako'
import {
  Activity,
  AppCurrency,
  AppUser,
  Article,
  AutopricingAssignment,
  CardAttributes,
  CardmarketAccount,
  CardmarketStatus,
  CardPriceGuideAttributes,
  CheapestMatchesResponse,
  createInventoryArticle,
  CSVImportSource,
  CustomHeaders,
  Game,
  GameName,
  IdGame,
  InventoryArticle,
  inventoryArticlesToStockChanges,
  JobResult,
  MKMOrder,
  Order,
  OrderArticle,
  PickingList,
  PlainInventoryArticle,
  PlainPricedInventoryArticle,
  PlainRichArticle,
  PricedInventoryArticle,
  PriceReport,
  PricingResult,
  PricingStrategy,
  Product,
  PTInventoryArticle,
  PTInventoryProduct,
  PublishChangesResponse,
  sleep,
  User,
  UserAttribute,
  UserAttributeName,
  UserSubEntity,
  UserSubEntityName,
} from 'shared'
import { GenericSetState } from 'types'
import { CancellationToken } from 'utils/cancellationToken'
import { v4 as uuid } from 'uuid'

const apiAddress = process.env.REACT_APP_API_ADDRESS
  ? `${process.env.REACT_APP_API_ADDRESS}/api`
  : '/api'

export const sendBugReport = async (report: string): Promise<{ message: string }> => {
  return API.post(`user/report-bug`, { report })
}

export const sendDevNotification = async (notification: string): Promise<{ message: string }> => {
  return API.post(`user/send-dev-notification`, { notification })
}

export const recordUsage = async (
  activity: Activity,
  details: Record<string, unknown>,
  googleTrackingData?: { category: string; value?: number; action: string }
): Promise<{ message: string }> => {
  if (googleTrackingData) window.dataLayer?.push({ event: 'app_event', ...googleTrackingData })
  return API.post(`user/usage`, { activity, details })
}

export const getCheapestMatches = async (
  article: InventoryArticle,
  competitorId: string,
  strategy: Pick<PricingStrategy, 'disablePlaysetPricing' | 'strictLanguage'>,
  idGame: IdGame
): Promise<CheapestMatchesResponse> => {
  return API.post(
    `user/pricing/cheapest-matches`,
    {
      article: article.toPlainObject(),
      competitorId,
      strategy,
    },
    { idGame }
  )
}

const compressIfBig = (data: unknown, headers: any) => {
  headers['Content-Type'] = 'application/json'

  const stringified = data ? JSON.stringify(data) : ''

  if (stringified.length > 1024 && process.env.NODE_ENV === 'production') {
    headers['Content-Encoding'] = 'gzip'
    return pako.gzip(stringified)
  } else {
    return stringified || data
  }
}

export const autopriceArticles = async (
  autopricingAssignments: AutopricingAssignment[],
  setProgress: GenericSetState,
  cancellationToken: CancellationToken
): Promise<Record<string, PriceReport>> => {
  let results: Record<string, PriceReport> = {}

  const response = await axios.post(
    apiAddress + '/user/pricing/autoprice',
    { autopricingAssignments },
    {
      withCredentials: true,
      transformRequest: compressIfBig,
    }
  )

  const jobId = response.data.jobId

  const totalArticleCount = autopricingAssignments.reduce(
    (acc, val) => acc + val.articles.length,
    0
  )

  while (Object.keys(results).length < totalArticleCount) {
    if (cancellationToken.cancelled) throw new Error('cancelled')
    await sleep(1000)
    const response = await API.get(`job/autopricing/${jobId}`)
    response.results.forEach((result: Record<string, PriceReport>) => {
      results = Object.assign(results, result)
    })
    setProgress(Math.round((Object.keys(results).length / totalArticleCount) * 100))
  }
  return results
}

interface PublishArticlesApiResponse {
  failed: { article: InventoryArticle; error: string }[]
  insertedCount: number
}

export const publishArticles = async (
  articles: InventoryArticle[],
  setProgress: GenericSetState,
  idGame: IdGame,
  cardmarketUsername: string
): Promise<PublishArticlesApiResponse> => {
  let data = await API.put(`user/stock`, {
    articlesToAdd: articles.map(
      (article) => {
        return article.applyChanges().attributes
      },
      { cardmarketUsername, idGame }
    ),
  })

  if (data.jobId) {
    const response: any = await getDataFromJobResult(
      data.jobId,
      'Could not publish stock. Try again later',
      (progress) => setProgress(progress)
    )

    //@ts-ignore
    data = {
      failed: articles
        .filter((article) => response.POSTfailures[article.idArticle])
        .map((article) => ({ article, error: response.POSTfailures[article.idArticle] })),
      insertedCount: articles.length - Object.keys(response?.POSTfailures ?? {}).length,
    }
  }

  if (typeof data.insertedCount !== 'number') throw new Error('Publishing failed')

  recordUsage(Activity.PublishArticles, { count: data.insertedCount, idGame })

  return data
}
export const publishChanges = async (
  articles: InventoryArticle[],
  setProgress: GenericSetState,
  idGame: IdGame
): Promise<PublishChangesResponse> => {
  const result: PublishChangesResponse = []

  let data = await API.put(
    `user/stock`,
    inventoryArticlesToStockChanges(articles) as Record<string, unknown>,
    { idGame }
  )

  if (data.jobId) {
    const response: any = await getDataFromJobResult(
      data.jobId,
      'Could not publish stock. Try again later',
      (progress) => setProgress(progress)
    )

    const POSTfailed = articles
      .filter((article) => response.POSTfailures[article.idArticle])
      .map((article) => ({ article, error: response.POSTfailures[article.idArticle] }))

    const DELETEfailed = articles
      .filter((article) => response.DELETEfailures[article.idArticle])
      .map((article) => ({ article, error: response.DELETEfailures[article.idArticle] }))

    const PUTfailed = articles
      .filter((article) => response.PUTfailures[article.idArticle])
      .map((article) => ({ article, error: response.PUTfailures[article.idArticle] }))

    const failed = POSTfailed.concat(DELETEfailed).concat(PUTfailed)

    //@ts-ignore
    data = {
      failed: failed,
      insertedCount: articles.length - failed.length,
    }
  }

  if (typeof data.insertedCount !== 'number') throw new Error('Publishing failed')

  recordUsage(Activity.PublishArticles, { count: data.insertedCount, idGame })

  data.failed.forEach((f: { article: InventoryArticle; error: string }) => {
    result.push({
      article: f.article,
      attributeChangeSuccess: false,
      quantityChangeSuccess: false,
      attributeError: f.error,
      quantityError: '',
    })
  })

  return result
}

const getDataFromJobResult = async (
  jobId: string,
  errorMessage: string,
  onProgress?: (progress: number) => void
) => {
  let data: JobResult | null = null

  while (!data?.result) {
    await new Promise((r) => setTimeout(r, 2000))
    data = await API.get(`job/${jobId}`)

    onProgress?.(data?.progress || 0)
  }
  if (!data?.result) throw new Error(errorMessage)
  return data?.result
}

export const getUser = async (): Promise<AppUser> => {
  return API.get('user')
}

export const getUserStock = async (
  idGame: Game['idGame'],
  isSealed = false,
  isGenericSealed = false
): Promise<Article['attributes'][]> => {
  let data
  if (isSealed === true && isGenericSealed === true) {
    data = await API.get(`user/stock`, { idGame, isSealed, isGenericSealed })
  } else if (isSealed === true) {
    data = await API.get(`user/stock`, { idGame, isSealed })
  } else {
    data = await API.get(`user/stock`, { idGame })
  }
  if (data.jobId) {
    const response = await getDataFromJobResult(
      data.jobId,
      'Could not fetch stock. Try again later'
    )

    //@ts-ignore
    data = response
  }

  return data
}

const getResource = async <T>(
  id: string,
  currentVersion?: string
): Promise<
  { resourceData: T; version: string; clientIsUpToDate: false } | { clientIsUpToDate: true }
> => {
  const resourceCheck = await API.get(`resource/${id}?currentVersion=${currentVersion}`)
  if (resourceCheck.clientIsUpToDate) return { clientIsUpToDate: true }

  if (!resourceCheck.url) throw new Error(`Could not get ${id}`)

  const response = await axios.get(resourceCheck.url as string, {
    withCredentials:
      resourceCheck.url.includes('tcgpowertools') || resourceCheck.url.includes('localhost'),
  })
  const data: T = response.data

  return {
    resourceData: data,
    version: resourceCheck?.version,
    clientIsUpToDate: false,
  }
}

export const getPriceGuideMap = async (idGame: IdGame, version?: string) => {
  return await getResource<Record<number, CardPriceGuideAttributes>>(
    `priceGuide_new_${idGame}`,
    version
  )
}

export const getCardData = async (idGame: IdGame, version?: string) => {
  return await getResource<{ cards: CardAttributes[]; expansions: any }>(`data_${idGame}`, version)
}

export const getCsvProducts = async <T>(idGame: IdGame) => {
  return (await getResource(`csvProducts_new_${idGame}`)) as {
    resourceData: T
    version: string
    clientIsUpToDate: false
  }
}

export const getCurrencyRate = async (
  from: AppCurrency,
  to: AppCurrency
): Promise<{ rate: number | null }> => {
  const data = await API.get(`status/currency/${from}/${to}`)
  return data
}

export const changeSubscription = async (announcedCost: number): Promise<UserSubEntity> => {
  return API.post(`user/subscription/start`, { announcedCost })
}
export const cancelSubscription = async ({
  unsubComment,
  unsubReasons,
}: {
  unsubComment: string
  unsubReasons: string
}): Promise<void> => {
  return API.post(`user/subscription/cancel`, { unsubComment, unsubReasons })
}
export const removeCancelSubscription = async (): Promise<void> => {
  return API.delete(`user/subscription/cancel`, {})
}

export const createUserSubEntity = async (
  subEntity: Omit<UserSubEntity, '_id'>,
  subEntityName: UserSubEntityName
): Promise<Partial<User>> => {
  return API.post(`user/${subEntityName}`, { ...subEntity, _id: uuid() })
}

export const editUserSubEntity = async (
  subEntity: UserSubEntity,
  subEntityName: UserSubEntityName
): Promise<Partial<User>> => {
  return API.put(`user/${subEntityName}`, { ...subEntity })
}

export const editUserAttribute = async (
  attribute: UserAttribute,
  attributeName: UserAttributeName
): Promise<Partial<User>> => {
  return API.put(`user`, { [attributeName]: attribute })
}

export const changeActiveGameId = async (
  activeGameId: Game['idGame'] /**todo this could be enum */
): Promise<{ activeGameId: number }> => {
  return API.put('user/set-active-game', { activeGameId })
}

export const deleteUserSubEntity = async (
  subEntityId: UserSubEntity['_id'],
  subEntityName: UserSubEntityName
): Promise<UserSubEntity> => {
  return API.delete(`user/${subEntityName}/${subEntityId}`)
}

export type CSVImportPayload = {
  csvString: string
  importSource: CSVImportSource
}

export interface CSVImportResponse {
  articles?: InventoryArticle[]
  failedArticles?: Record<string, unknown>[]
}

export const getArticlesFromCsv = async (
  payload: CSVImportPayload,
  gameName: GameName
): Promise<CSVImportResponse> => {
  return API.post(`import-csv/${gameName}`, payload)
}

export const resetAssignedAccounts = async (): Promise<User> => {
  return API.get('user/reset-assigned-accounts')
}

export const disconnectCardmarketAccount = async (): Promise<{ message: string }> => {
  return API.get('user/disconnect-cardmarket')
}

export const switchCardmarketAccount = async (username: string): Promise<Partial<User>> => {
  return API.put(`user/cardmarket/account`, { cardmarketUsername: username })
}

export const getCardmarketStatus = async (): Promise<{
  status: CardmarketStatus
  banner?: { message: string; _id: string }
}> => {
  return API.get('status/cardmarket')
}

export const getSurvey = async (): Promise<{ Id: string; PostId: string }> => {
  return API.get('user/survey')
}

export const markSurveyCompleted = async (surveyId: string): Promise<void> => {
  return API.put(`user/survey/${surveyId}`, {})
}

export const getSubscriptionOffer = async (): Promise<{
  currentSubscriptionOffer: number | null
}> => {
  const data = await API.get('user/subscription/offer')

  if (!data.jobId) return { currentSubscriptionOffer: null }

  const response = await getDataFromJobResult(data.jobId, '')

  return response as { currentSubscriptionOffer: number | null }
}

export const getPriceUpdates = async (
  idGame: IdGame
): Promise<{
  updates: PricingResult[]
}> => {
  return API.get(`user/pricing/updates`, { idGame })
}

export const getPriceUpdateArticles = async (
  priceUpdateId: string
): Promise<(PricedInventoryArticle | InventoryArticle)[]> => {
  const { nonCompetitionArticlesLink, articlesLink } = await API.get(
    `user/pricing/update/${priceUpdateId}`
  )

  let articles: (PlainPricedInventoryArticle | PlainInventoryArticle)[] = []

  if (nonCompetitionArticlesLink) {
    const response1 = await axios.get(nonCompetitionArticlesLink)
    const nonCompetitionArticles = response1.data

    articles = [...articles, ...nonCompetitionArticles]
  }

  if (articlesLink) {
    const response1 = await axios.get(articlesLink)
    const competitionArticles = response1.data

    articles = [...articles, ...competitionArticles]
  }

  return articles.map((article) => createInventoryArticle(article))
}

export const importFile = async (rows: any, idGame: IdGame) => {
  const data = await API.post('import', { rows }, { idGame })

  if (!data.jobId) throw new Error('No jobId')

  const response = (await getDataFromJobResult(data.jobId, '')) as {
    parsedArticles: PlainRichArticle[]
    failedArticles: Record<string, unknown>[]
  }

  response.parsedArticles = response.parsedArticles.map((article) => new InventoryArticle(article))

  return response as {
    parsedArticles: InventoryArticle[]
    failedArticles: Record<string, unknown>[]
  }
}

export const runPriceUpdate = async (
  pricingPlanId: string,
  idGame: IdGame,
  cardmarketUsername: string
): Promise<{
  jobId: string
}> => {
  return API.post(`user/pricing/test-run`, { pricingPlanId }, { idGame, cardmarketUsername })
}

export const getCurrentVersion = async (): Promise<{ version: string }> => {
  return await axios.get(`${process.env.REACT_APP_API_ADDRESS || ''}/meta.json`)
}

export const changeBotActivation = async (
  account: CardmarketAccount,
  idGame: IdGame,
  planId: string | 'none'
): Promise<{ assignedCardmarketAccounts: CardmarketAccount[] }> => {
  if (planId === 'none') {
    return await API.delete(`user/pricingBots`, { account: account.username, idGame })
  } else {
    return await API.post(`user/pricingBots`, { account: account.username, planId, idGame })
  }
}

export const setUserOutsetaAttributes = (payload: {
  emailPreferences?: {
    gettingStarted: boolean
    productNews: boolean
    surveys: boolean
  }
  howDidYouFindOut?: string
  referralCode?: string
}): Promise<{ done: true }> => {
  return API.put('user/outseta', payload)
}

export const createStripeSession = (returnPath: string): Promise<{ sessionId: string }> => {
  return API.get(`user/billing/setup-sepa-mandate?returnPath=${returnPath}`)
}

export const setupSepaMandate = (sessionId: string): Promise<{ done: boolean }> => {
  return API.post(`user/billing/setup-sepa-mandate`, { sessionId })
}

export const getApiClient = (apiAddress: string, config: AxiosRequestConfig) => {
  return {
    get: async (endpoint: string, headers: CustomHeaders = {}) => {
      return await axios
        .get(`${apiAddress}/${endpoint}`, { ...config, headers: { ...headers, ...config.headers } })
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    post: async (
      endpoint: string,
      payload: Record<string, unknown> | FormData,
      headers: CustomHeaders = {}
    ) => {
      return await axios
        .post(`${apiAddress}/${endpoint}`, payload, {
          ...config,
          headers: { ...headers, ...config.headers },
        })
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    put: async (
      endpoint: string,
      payload: Record<string, unknown>,
      headers: CustomHeaders = {}
    ) => {
      return await axios
        .put(`${apiAddress}/${endpoint}`, payload, {
          ...config,
          headers: { ...headers, ...config.headers },
        })
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    delete: async (
      endpoint: string,
      payload?: Record<string, unknown>,
      headers: CustomHeaders = {}
    ) => {
      return await axios
        .delete(`${apiAddress}/${endpoint}`, {
          ...config,
          headers: { ...headers, ...config.headers },
          data: payload,
        })
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
  } as const
}

const API = getApiClient(apiAddress, {
  withCredentials: true,
  transformRequest: compressIfBig,
})

export const getCpuStatus = async (params: StatusCallParams): Promise<any> => {
  return await API.get(`backoffice/status/cpu?server=${params.server}&env=${params.env}`)
}

export const getMemoryStatus = async (params: StatusCallParams): Promise<any> => {
  return await API.get(`backoffice/status/memory?server=${params.server}&env=${params.env}`)
}

export const getHddStatus = async (params: StatusCallParams): Promise<any> => {
  return await API.get(`backoffice/status/hdd?server=${params.server}&env=${params.env}`)
}

export const getQueueStatus = async (params: StatusCallParams): Promise<any> => {
  return await API.get(`backoffice/status/queue?queue=${params.queue}`)
}

export const getRedisMemory = async (): Promise<any> => {
  return await API.get(`backoffice/status/redisMemory`)
}

export const getMongoCollectionStatus = async (params: StatusCallParams): Promise<any> => {
  return await API.get(
    `backoffice/status/collections?collection=${params.collection}&maxValue=${params.maxValue}`
  )
}

export const getRedisCacheStatus = async (params: StatusCallParams): Promise<any> => {
  return await API.get(`backoffice/status/cache?maxValue=${params.maxValue}`)
}

export const getSmallestTTL = async (params: StatusCallParams): Promise<any> => {
  return await API.get(
    `backoffice/status/smallestTTL?minValue=${params.minValue}&maxValue=${params.maxValue}`
  )
}

export const getNumArticlesFetched = async (params: StatusCallParams): Promise<any> => {
  return await API.get(`backoffice/status/numArticlesFetched?minValue=${params.minValue}`)
}

export const getFetcherMetrics = async (): Promise<any> => {
  return await API.get(`backoffice/status/fetcherMetrics`)
}

export const getTimeLastArticleDataBackup = async (params: StatusCallParams): Promise<any> => {
  return await API.get(`backoffice/status/timeLastArticleDataBackup?maxValue=${params.maxValue}`)
}

export const getTimeLastProductIdsBackup = async (params: StatusCallParams): Promise<any> => {
  return await API.get(`backoffice/status/timeLastProductIdsBackup?maxValue=${params.maxValue}`)
}

export const getTimeLastExpansionsBackup = async (params: StatusCallParams): Promise<any> => {
  return await API.get(`backoffice/status/timeLastExpansionsBackup?maxValue=${params.maxValue}`)
}

export const getTimeLastCategoriesBackup = async (params: StatusCallParams): Promise<any> => {
  return await API.get(`backoffice/status/timeLastCategoriesBackup?maxValue=${params.maxValue}`)
}

export const getRedisCacheSize = async (params: StatusCallParams): Promise<any> => {
  return await API.get(`backoffice/status/redisCacheSize?minValue=${params.minValue}`)
}

export const getFetcherRuntimeMain = async (): Promise<any> => {
  return await API.get(`backoffice/status/fetcherRuntimeMain`)
}

export const getFetcherRuntimeFast = async (): Promise<any> => {
  return await API.get(`backoffice/status/fetcherRuntimeFast`)
}

export const getCountOldArticles = async (params: StatusCallParams): Promise<any> => {
  return await API.get(`backoffice/status/countOldArticles?maxValue=${params.maxValue}`)
}

export const get429Errors = async (): Promise<any> => {
  return await API.get(`backoffice/status/429Errors`)
}

export const getPageLoadingTime = async (params: StatusCallParams): Promise<any> => {
  return await API.get(`backoffice/status/pageLoad?env=${params.env}&maxValue=${params.maxValue}`)
}

export const getMongoQueryResponseTime = async (params: StatusCallParams): Promise<any> => {
  return await API.get(
    `backoffice/status/queryResponseTime?collection=${params.collection}&maxValue=${params.maxValue}`
  )
}

export const getLastProductsUpdate = async (params: StatusCallParams): Promise<any> => {
  return await API.get(`backoffice/status/lastProductsUpdate?maxValue=${params.maxValue}`)
}

export const cleanQueue = async (
  queueName: string,
  grace: number,
  limit: number,
  type: string,
  password: string
): Promise<any> => {
  return await API.put(`backoffice/cleanQueue`, { queueName, grace, limit, type, password })
}

export const drainQueue = async (queueName: string, password: string): Promise<any> => {
  return await API.put(`backoffice/drainQueue`, { queueName, password })
}

export const checkSentry = async (): Promise<any> => {
  return await API.get(`kaboom`)
}

export const fetchProduct = async (id: number): Promise<Product> => {
  return await API.get(`product/fetch?id=${id}`)
}

export const fetchProducts = async (ids: number[]): Promise<Product[]> => {
  return await API.post(`product/fetchList`, { ids })
}

export const fetchOrders = async (): Promise<Order[]> => {
  return await API.get(`user/order`)
}

export const fetchOrdersFromCardmarket = async (): Promise<any> => {
  console.log('fetching orders from cardmarket...')
  return await API.post(`user/updateCardmarketOrders`, {})
}

export const fetchOrdersFromCardmarketAsync = async (): Promise<any> => {
  console.log('fetching orders from cardmarket -- queue version...')
  const { jobId } = await API.post(`user/updateCardmarketOrdersAsync`, {})
  let jobStatus = 'queued'
  while (jobStatus && !['completed', 'failed'].includes(jobStatus)) {
    await sleep(2000)
    jobStatus = await API.get(`job/status/${jobId}`)
  }

  return
}

export const changeArticleState = async (
  idOrder: number,
  idArticle: number,
  state: string
): Promise<Order> => {
  return await API.put(`user/order/changeArticleState`, { idOrder, idArticle, state })
}

export const changeArticleProp = async (
  idOrder: number,
  idArticle: number,
  prop: string,
  value: any
): Promise<Order> => {
  return await API.put(`user/order/changeArticleProp`, {
    idOrder,
    idArticle,
    prop,
    value,
  })
}

export const createPTOrder = async (order: Order, newState: string): Promise<Order> => {
  return await API.put(`user/order/createPTOrder`, { order, newState })
}

export const createPTOrders = async (orderIds: number[], newState: string): Promise<Order[]> => {
  return await API.put(`user/order/createPTOrders`, { orderIds, newState })
}

export const resetPTOrders = async (orderIds: number[]): Promise<Order[]> => {
  return await API.put(`user/order/resetPTOrders`, { orderIds })
}

export const changeOrderState = async (idOrder: number, newState: string): Promise<Order> => {
  return await API.put(`user/order/changeOrderState`, { idOrder, newState })
}

export const changeOrdersState = async (orderIds: number[], newState: string): Promise<Order[]> => {
  return await API.put(`user/order/changeOrdersState`, { orderIds, newState })
}

export const changeOrderProp = async (
  idOrder: number,
  prop: string,
  value: any
): Promise<Order> => {
  return await API.put(`user/order/changeOrderProp`, {
    idOrder,
    prop,
    value,
  })
}

export const saveArticleProblem = async (
  orderArticle: OrderArticle,
  problem: string
): Promise<Order> => {
  return await API.put(`user/order/saveArticleProblem`, { orderArticle, problem })
}

export const saveOrderProblem = async (order: Order, problem: string): Promise<Order> => {
  return await API.put(`user/order/saveOrderProblem`, { order, problem })
}

export const createPickingList = async (
  cardmarketUsername: string,
  orderIds: number[]
): Promise<PickingList> => {
  return await API.put(`user/order/createPickingList`, { cardmarketUsername, orderIds })
}

export const getUserPickingLists = async (
  cardmarketUsername: string,
  state: string
): Promise<PickingList[]> => {
  return await API.get(
    `user/order/getUserPickingLists?cardmarketUsername=${cardmarketUsername}&state=${state}`
  )
}

export const updatePickingList = async (
  pickingListId: string,
  field: string,
  value: any
): Promise<PickingList> => {
  return await API.put(`user/order/updatePickingList`, {
    pickingListId,
    field,
    value,
  })
}

export const deletePickingList = async (
  pickingList: PickingList,
  cardmarketUsername: string
): Promise<number[]> => {
  return await API.put(`user/order/deletePickingList`, {
    pickingList,
    cardmarketUsername,
  })
}

export const getArticlesToPick = async (pickingListId: string): Promise<OrderArticle[]> => {
  return await API.put('user/order/getArticlesToPick', { pickingListId })
}

export const getPickingListInfo = async (
  cardmarketUsername: string,
  orderId: string
): Promise<PickingList> => {
  return await API.get(
    `user/order/getPickingListInfo?cardmarketUsername=${cardmarketUsername}&orderId=${orderId}`
  )
}

export const getPickingListsInfo = async (
  cardmarketUsername: string,
  orderIds: number[]
): Promise<PickingList[]> => {
  return await API.put(`user/order/getPickingListsInfo`, { cardmarketUsername, orderIds })
}

export const removeOrderFromPickingList = async (
  pickingListName: string,
  orderId: number
): Promise<Order> => {
  return await API.put(`user/order/removeOrderFromPickingList`, {
    pickingListName,
    orderId,
  })
}

export const setTrackingNumber = async (
  idOrder: number,
  trackingNumber: string
): Promise<PickingList[]> => {
  return await API.put(`user/order/setTrackingNumber`, { idOrder, trackingNumber })
}

export const exportOrders = async (date: Date): Promise<MKMOrder[]> => {
  return await API.get(`user/order/exportOrders?date=${date}`)
}

export const getInternalExportOrders = async (date: Date, password: string): Promise<string> => {
  return await API.get(`export/internalExportOrders?date=${date}&password=${password}`)
}

export const generateEbayAuthUrl = async () => {
  return await API.get(`ebay/generateEbayAuthUrl`)
}

export const getEbayAccessToken = async (encodedAuthToken: string) => {
  return await API.get(`ebay/getEbayAccessToken?encodedAuthToken=${encodedAuthToken}`)
}

export const ebayDisconnect = async () => {
  return await API.put(`ebay/ebayDisconnect`, {})
}

export const getEbayOfficialTime = async () => {
  return await API.get(`ebay/getEbayOfficialTime`)
}

export const getEbayOrders = async () => {
  return await API.get(`ebay/getEbayOrders`)
}

export const createEbayAddTask = async () => {
  return await API.get(`ebay/createEbayAddTask`)
}

export const getEbayTask = async (taskId: string) => {
  return await API.get(`ebay/getEbayTask?taskId=${taskId}`)
}

export const uploadEbayFile = async (taskId: string, fileName: string, fileContent: string) => {
  return await API.post(`ebay/uploadEbayFile`, { taskId, fileName, fileContent })
}

export const getEbayResultFile = async (taskId: string) => {
  return await API.get(`ebay/getEbayResultFile?taskId=${taskId}`)
}

export const createEbayEndTask = async () => {
  return await API.get(`ebay/createEbayEndTask`)
}

export const ebayListItems = async (articles: InventoryArticle[]) => {
  return await API.post(`ebay/ebayListItems`, { articles })
}

export const ebayRemoveItems = async (articles: InventoryArticle[]) => {
  return await API.post(`ebay/ebayRemoveItems`, { articles })
}

export const getEbayUser = async () => {
  return await API.get(`ebay/getEbayUser`)
}

export const addInventoryItem = async (article: PTInventoryArticle) => {
  return await API.post(`user/inventory/addItem`, { article })
}

export const loadInventory = async (): Promise<PTInventoryProduct[]> => {
  return await API.get(`user/inventory`)
}

export const clearInventory = async () => {
  return await API.post(`user/inventory/clear`, {})
}

export const deleteInventoryItem = async (article: PTInventoryArticle) => {
  return await API.delete(`user/inventory/deleteItem`, { article })
}

export const editInventoryItem = async (newArticle: PTInventoryArticle) => {
  return await API.put(`user/inventory/editItem`, { newArticle })
}
