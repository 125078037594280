import { Box, Typography } from '@material-ui/core'
import { AccountSettingSelect } from 'components/controls/misc'
import { useTranslation } from 'react-i18next'
import { APP_LANGUAGES } from 'utils/constants'
import { CardmarketConnect } from '../cardmarket'
import { ReferralPresenter } from '../dashboard/ReferralPresenter'

export const AccountSettingsPresenter = (): JSX.Element => {
  const { t, i18n } = useTranslation()

  return (
    <>
      <Box marginTop={2} maxWidth="400px">
        <Typography>{t('subscription.accountsTitle')}</Typography>
        <CardmarketConnect noHelperText />
      </Box>
      <Box marginTop={2}>
        <Typography>{t('common.AppliactionLanguage')}</Typography>
        <AccountSettingSelect
          attribute={'language'}
          options={APP_LANGUAGES}
          onChange={(language) => i18n.changeLanguage(language as string)}
        />
      </Box>
      <Box marginTop={5}>
        <Typography>{t('signup.referral')}</Typography>
        <ReferralPresenter />
      </Box>
    </>
  )
}
