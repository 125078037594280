import { Box, Grid, Typography } from '@material-ui/core'
import { OrderArticle } from 'shared'
import { CardInfoBox } from './CardInfoBox'
import { OrderVisualArticle } from './OrderVisualArticle'
import { PickingButton } from './PickingButton'

export interface SingleImagePickingProps {
  article: OrderArticle
  handlePick?: (article: OrderArticle) => void
  handleCompletePicking?: (article: OrderArticle, quantity: number) => void
  handleReportIssue?: (a: OrderArticle) => void
}

export const SingleImagePicking = ({
  article,
  handlePick,
  handleCompletePicking,
  handleReportIssue,
}: SingleImagePickingProps): JSX.Element => {
  return (
    <Grid container spacing={5}>
      {article ? (
        <>
          <Grid item xs={6}>
            <OrderVisualArticle article={article} fullScreen hideBar hideButtons />
          </Grid>
          <Grid item xs={3} style={{ alignSelf: 'center' }}>
            <PickingButton
              type="reportIssue"
              article={article}
              handleReportIssue={handleReportIssue}
              padding="10px"
              fullWidth
            />
            <Box padding={5} />
            <CardInfoBox article={article} fullScreen transparent={false} />
            <Box padding={3} />
            <PickingButton
              type="pick"
              article={article}
              handlePick={handlePick}
              padding="10px"
              fullWidth
            />
            <Box padding={1} />
            <PickingButton
              type="put"
              article={article}
              handleCompletePicking={handleCompletePicking}
              padding="10px"
              fullWidth
            />
          </Grid>

          <Grid item xs={3}></Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Box textAlign="center">
            <Typography variant="h6">No more articles to pick</Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}
