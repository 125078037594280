import { Box, Chip, InputBase, Tooltip, Typography } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { Button } from 'components/facets'
import { useDebounce, useDialog, useOrderStore } from 'lib/hooks'
import { getGameCodeFromName } from 'lib/utils'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getOrderState,
  hasIssues,
  Order,
  OrderArticle,
  orderContainsSealed,
  OrderFilter,
  ORDER_FILTERS,
  ORDER_TABS,
} from 'shared'
import { ExportOrders } from '../csv/ExportOrders'
import { ColorBox } from './ColorBox'
import { PickingListToolbar } from './PickingListToolbar'

export interface OrdersToolbarProps {
  handleChangeOrderState: (orderIds: number[], newState: string) => void
}

export const OrdersToolbar = ({ handleChangeOrderState }: OrdersToolbarProps): JSX.Element => {
  const { t } = useTranslation()
  const orders = useOrderStore((state) => state.orders)
  const filteredOrders = useOrderStore((state) => state.filteredOrders)
  const filteredArticles = useOrderStore((state) => state.filteredArticles)
  const view = useOrderStore((state) => state.view)
  const articles = useOrderStore((state) => state.articles)
  const filterByOrder = useOrderStore((state) => state.filterByOrder)
  const startDate = useOrderStore((state) => state.startDate)
  const endDate = useOrderStore((state) => state.endDate)
  const activeTab = useOrderStore((state) => state.activeTab)
  const activeOrdersFilters = useOrderStore((state) => state.activeOrdersFilters)
  const loadingFromMkm = useOrderStore((state) => state.loadingFromMkm)
  const setFilterByOrder = useOrderStore((state) => state.setFilterByOrder)
  const setFilteredArticles = useOrderStore((state) => state.setFilteredArticles)
  const setFilteredOrders = useOrderStore((state) => state.setFilteredOrders)
  const setActiveOrdersFilters = useOrderStore((state) => state.setActiveOrdersFilters)

  const [setExportDialogOpen, ExportDialog] = useDialog()

  const [searchString, setSearchString] = useState('')
  const debounceTime = 200
  const debouncedSearchString = useDebounce(searchString, debounceTime)

  const openExportOrdersDialog = () => {
    setExportDialogOpen(true)
  }

  const orderContainsArticle = (order: Order, articleName: string) => {
    for (const a of order.article) {
      if (
        a.product.enName.toLowerCase().includes(articleName.toLowerCase()) ||
        a.product.locName.toLowerCase().includes(articleName.toLowerCase())
      ) {
        return true
      }
    }
  }

  const orderContainsExpansion = (order: Order, expansionName: string) => {
    for (const a of order.article) {
      if (a.product.expansion?.toLowerCase().includes(expansionName.toLowerCase())) {
        return true
      }
    }
  }

  const filterForIssues = (stateFilters: string[], order: Order) => {
    if (stateFilters.includes('issues')) {
      return hasIssues(order)
    }
    return true
  }

  const filterByGames = (stateFilters: string[], order: Order) => {
    for (const game of order.pt.articleGames) {
      if (stateFilters.includes(game)) {
        return true
      }
    }
    return false
  }

  const filterByItemType = (stateFilters: string[], order: Order) => {
    if (stateFilters.includes('sealed')) {
      return orderContainsSealed(order)
    } else {
      return true
    }
  }

  const filterByPresale = (stateFilters: string[], order: Order) => {
    if (stateFilters.includes('presale')) {
      return order.isPresale
    } else {
      return true
    }
  }

  const orderContainsComment = (order: Order, comment: string) => {
    let filtered: OrderArticle[] = []
    if (comment.includes('"')) {
      filtered = order.article.filter((a) => a.comments.toLowerCase() === comment.replace(/"/g, ''))
    } else {
      filtered = order.article.filter((a) => a.comments.toLowerCase().includes(comment))
    }
    return filtered.length > 0
  }

  const filterOrders = () => {
    if (activeTab !== -1) {
      let newFilteredOrders = [...orders]

      newFilteredOrders = newFilteredOrders.filter(
        (o) =>
          ORDER_TABS[activeTab].states.includes(getOrderState(o)) ||
          ORDER_TABS[activeTab].states.includes('')
      )
      newFilteredOrders = newFilteredOrders.filter(
        (o) => new Date(o.pt.updatedAt) >= startDate && new Date(o.pt.updatedAt) <= endDate
      )
      if (debouncedSearchString) {
        newFilteredOrders = newFilteredOrders.filter(
          (o) =>
            o.idOrder.toString().startsWith(debouncedSearchString) ||
            o.buyer.username.toLowerCase().includes(debouncedSearchString.toLowerCase()) ||
            o.buyer.address.name.toLowerCase().includes(debouncedSearchString.toLowerCase()) ||
            orderContainsArticle(o, debouncedSearchString) ||
            orderContainsExpansion(o, debouncedSearchString) ||
            orderContainsComment(o, debouncedSearchString)
        )
      }

      // filter by order state
      const stateFilters: string[] = []
      activeOrdersFilters.forEach((af) => {
        const filterConfig = ORDER_FILTERS.find((f) => f.state === af)
        stateFilters.push(filterConfig?.state || '')
      })
      newFilteredOrders = newFilteredOrders.filter((o) => stateFilters.includes(getOrderState(o)))

      // filter by issues
      newFilteredOrders = newFilteredOrders.filter((o) => filterForIssues(stateFilters, o))

      // filter by game
      newFilteredOrders = newFilteredOrders.filter((o) => filterByGames(stateFilters, o))

      // filter by items type (choose between showing order with just singles or orders
      // with singles + sealed)
      newFilteredOrders = newFilteredOrders.filter((o) => filterByItemType(stateFilters, o))

      // filter by presale or "normal"
      newFilteredOrders = newFilteredOrders.filter((o) => filterByPresale(stateFilters, o))

      // sort orders
      newFilteredOrders = getSortedOrders(newFilteredOrders)

      setFilteredOrders(newFilteredOrders)
    }
  }

  const filterArticles = () => {
    let newFilteredArticles = [...filteredArticles]
    if (debouncedSearchString) {
      newFilteredArticles = newFilteredArticles.filter(
        (a) =>
          a.product.enName.toLowerCase().includes(debouncedSearchString.toLowerCase()) ||
          a.product.locName.toLowerCase().includes(debouncedSearchString.toLowerCase()) ||
          a.properties?.toLowerCase().includes(debouncedSearchString.toLowerCase()) ||
          a.product.expansion?.toLowerCase().includes(debouncedSearchString.toLowerCase()) ||
          a.buyer?.username.toLowerCase().includes(debouncedSearchString.toLowerCase())
      )
    }
    newFilteredArticles = newFilteredArticles.filter((a) => filterByOrder.includes(a.idOrder))
    setFilteredArticles(newFilteredArticles)
  }

  useEffect(() => {
    filterOrders()
    filterArticles()
  }, [activeTab, activeOrdersFilters, startDate, endDate, debouncedSearchString, orders])

  useEffect(() => {
    filterArticles()
  }, [filterByOrder])

  const sortOrdersFn = (a: Order, b: Order): number => {
    if (activeTab === 1) {
      if (a.pt.picking && b.pt.picking && a.pt.picking.pickingList && b.pt.picking.pickingList) {
        const sortingFactor = a.pt.picking.pickingList.localeCompare(b.pt.picking.pickingList)
        if (sortingFactor === 0) {
          return a.pt.picking.pickingId.localeCompare(b.pt.picking.pickingId)
        }
        return sortingFactor
      } else if (
        a.pt.picking &&
        b.pt.picking &&
        a.pt.picking.pickingList &&
        !b.pt.picking.pickingList
      ) {
        return -1
      } else {
        return 1
      }
    } else {
      return new Date(a.state.datePaid).getTime() < new Date(b.state.datePaid).getTime() ? 1 : -1
    }
  }

  const getSortedOrdersForPicking = (orders: Order[]) => {
    const paid: Order[] = []
    const picking: Order[] = []
    orders.forEach((o) => {
      const state = getOrderState(o)
      if (state === 'paid') {
        paid.push(o)
      } else if (state === 'picking') {
        picking.push(o)
      }
    })

    paid.sort((a, b) => {
      const datePaidA = new Date(a.state.datePaid)
      const datePaidB = new Date(b.state.datePaid)
      return datePaidA.getTime() - datePaidB.getTime()
    })

    const pickingWithCorrectFields = picking.filter((o) => o.pt.picking)
    // this is for debugging in staging
    console.log(
      `picking: ${picking.length}, pickingWithCorrectFields: ${pickingWithCorrectFields.length}`
    )

    pickingWithCorrectFields.sort((a, b) => {
      return (
        a.pt.picking.pickingList.localeCompare(b.pt.picking.pickingList) ||
        a.pt.picking.pickingId.localeCompare(b.pt.picking.pickingId)
      )
    })

    return paid.concat(pickingWithCorrectFields)
  }

  const getSortedOrders = (orders: Order[]) => {
    if (activeTab === 1) {
      // custom sorting for picking lists
      return getSortedOrdersForPicking(orders)
    }
    return orders.sort((a, b) => sortOrdersFn(a, b))
  }

  const handleChangeSearchInput = (e: any) => {
    setSearchString(e.target.value)
  }

  const getGamesInOrders = () => {
    const games: Set<string> = new Set()
    orders.forEach((o) => {
      if (o.pt.articleGames[0]) {
        games.add(o.pt.articleGames[0])
      }
    })
    return Array.from(games)
  }

  const getOrderFilters = () => {
    const orderFilters: OrderFilter[] = []
    if (activeTab !== -1) {
      ORDER_TABS[activeTab].filters.forEach((filterName) => {
        const of = ORDER_FILTERS.find((f) => f.name === filterName)
        if (of) {
          orderFilters.push(of)
        }
      })
    }
    return orderFilters
  }

  const handleClickOrderFilter = (state: string) => {
    let newActiveOrdersFilters = [...activeOrdersFilters]
    if (newActiveOrdersFilters.includes(state)) {
      if (state === 'all') {
        newActiveOrdersFilters = []
      } else {
        const idx = newActiveOrdersFilters.indexOf(state)
        newActiveOrdersFilters.splice(idx, 1)
      }
    } else {
      if (state === 'all') {
        newActiveOrdersFilters = ORDER_FILTERS.filter((f) => f.defaultActive).map((f) => f.state)
      } else {
        newActiveOrdersFilters.push(state)
      }
    }

    setActiveOrdersFilters(newActiveOrdersFilters)
  }

  const toolNamesWidth = '70px'

  return (
    <>
      <Box display="flex" paddingY={1}>
        <Box alignSelf="center" width={toolNamesWidth}>
          <Typography color="primary">{t('table.search')}</Typography>
        </Box>

        <Box border={1} style={{ borderRadius: '40px' }} width="70%">
          <Box display="flex">
            <Box marginLeft={1} alignSelf="self-end">
              <Search />
            </Box>
            <Box marginLeft={1} width="95%">
              <InputBase
                placeholder={t('orders.searchBar')}
                fullWidth
                onChange={handleChangeSearchInput}
              />
            </Box>
          </Box>
        </Box>

        {/* <Box marginLeft={1} alignSelf="self-end" onClick={handleOpenDateFilters}>
            <DateRange />
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} style={{ zIndex: 11 }}>
              <Box padding={1} width="320px">
                <Paper elevation={10}>
                  <Box padding={1}>
                    <DateFilters />
                    <Box padding={1}></Box>
                    <ColorBox
                      type="state"
                      name="Today"
                      state="Today"
                      active={false}
                      onClick={() => console.log('today')}
                    />
                    <Box padding={0.5}></Box>
                    <ColorBox
                      type="state"
                      name="This week"
                      state="This week"
                      active={false}
                      onClick={() => console.log('this week')}
                    />
                    <Box padding={0.5}></Box>
                    <ColorBox
                      type="state"
                      name="This month"
                      state="This month"
                      active={false}
                      onClick={() => console.log('this month')}
                    />
                    <Box padding={0.5}></Box>
                    <ColorBox
                      type="state"
                      name="Last 3 months"
                      state="Last 3 months"
                      active={false}
                      onClick={() => console.log('last 3 months')}
                    />
                    <Box padding={0.5}></Box>
                    <ColorBox
                      type="state"
                      name="Last 6 months"
                      state="Last 6 months"
                      active={false}
                      onClick={() => console.log('last 6 months')}
                    />
                  </Box>
                </Paper>
              </Box>
            </Popper>
          </Box> */}

        <Box width="25%">
          <Button
            variant="outlined"
            onClick={openExportOrdersDialog}
            data-testid="open-export-orders-dialog-btn"
            style={{ float: 'right' }}
          >
            {t('csv.export')}
          </Button>
        </Box>
      </Box>

      {activeTab === 1 && (
        <Box display="flex" paddingY={1}>
          <PickingListToolbar
            toolNamesWidth={toolNamesWidth}
            handleChangeOrderState={handleChangeOrderState}
          />
        </Box>
      )}

      {/* {activeTab === 3 && (
        <Box display="flex" paddingY={1}>
          <ShippingToolbar
            toolNamesWidth={toolNamesWidth}
            handleChangeOrderState={handleChangeOrderState}
          />
        </Box>
      )} */}

      <Box display="flex" paddingY={1}>
        <Box width={toolNamesWidth} alignSelf="center">
          <Typography color="primary">{t('filters.title')}</Typography>
        </Box>
        {getOrderFilters().map((f) => (
          <ColorBox
            key={f.name}
            type="filter"
            name={f.name}
            state={f.state}
            active={activeOrdersFilters.includes(f.state)}
            clickable
            onClick={handleClickOrderFilter}
          />
        ))}

        {!loadingFromMkm &&
          getGamesInOrders().map((g) => (
            <Box key={g} marginX="5px" onClick={() => handleClickOrderFilter(g)}>
              <Tooltip title={g}>
                <span
                  className={`ss-${getGameCodeFromName(g)} ss-game-icon ss-fw`}
                  style={{
                    fontSize: '1.3rem',
                    cursor: 'pointer',
                    opacity: activeOrdersFilters.includes(g) ? 1 : 0.4,
                  }}
                ></span>
              </Tooltip>
            </Box>
          ))}
      </Box>

      {view === 'article' && filterByOrder.length > 0 && (
        <Box display="flex" paddingX={1}>
          {filterByOrder.map((o) => {
            return (
              <Chip
                key={o}
                label={o}
                onDelete={() => {
                  const newFilterByOrder = [...filterByOrder]
                  const idx = newFilterByOrder.indexOf(o)
                  if (idx !== -1) {
                    newFilterByOrder.splice(idx, 1)
                    setFilterByOrder(newFilterByOrder)
                  }
                }}
                style={{ marginRight: '4px' }}
              ></Chip>
            )
          })}
        </Box>
      )}

      <ExportDialog
        title={t('csv.export')}
        content={
          <ExportOrders orders={orders} filteredOrders={filteredOrders} articles={articles} />
        }
      />
      {/* <ExportPickingListDialog
        title={t('csv.export')}
        content={<ExportPickingList articles={filteredArticles} />}
      /> */}
    </>
  )
}
