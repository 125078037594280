import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { ConditionBadge } from 'components/facets/ConditionBadge'
import firstEdIcon from 'img/firstEdIcon.png'
import foilIcon from 'img/foilIcon.png'
import reverseHoloIcon from 'img/reverseHoloIcon.png'
import signedIcon from 'img/signedIcon.png'
import { getPlaysetCoefficient } from 'lib/utils'
import { useTranslation } from 'react-i18next'
import Flag from 'react-world-flags'
import { OrderArticle } from 'shared'
import { LANGUAGE_MAP_ICONS } from 'utils/constants'
import { ColorBox } from './ColorBox'

export interface ArticlesTableProps {
  articles: OrderArticle[]
  setSelectedArticle: (article: OrderArticle) => void
  setOpenReportIssueDialog: (open: boolean) => void
}

export const ArticlesTable = ({
  articles,
  setSelectedArticle,
  setOpenReportIssueDialog,
}: ArticlesTableProps): JSX.Element => {
  const { t } = useTranslation()
  const iconHeight = '17px'

  return (
    <>
      <TableContainer component={Paper}>
        {/* <TablePagination
            page={0}
            rowsPerPage={10}
            count={12}
            onPageChange={() => console.log(123)}
          /> */}
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell>
                <b>{t('orders.position')}</b>
              </TableCell> */}
              <TableCell>
                <b>{t('card.name')}</b>
              </TableCell>
              <TableCell>
                <b>{t('card.expansion')}</b>
              </TableCell>
              <TableCell>
                <b>{t('card.cn')}</b>
              </TableCell>
              <TableCell>
                <b>{t('card.rarity')}</b>
              </TableCell>
              <TableCell>
                <b>{t('orders.quantity')}</b>
              </TableCell>
              <TableCell>
                <b>{t('orders.properties')}</b>
              </TableCell>
              {/* <TableCell>
                <b>{t('orders.price')}</b>
              </TableCell> */}
              <TableCell>
                <b>{t('orders.comments')}</b>
              </TableCell>
              <TableCell>
                <b>{t('orders.issues')}</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {articles.map((a) => (
              <TableRow key={a.idArticle}>
                {/* <TableCell>{a.pos}</TableCell> */}
                <TableCell>
                  <Box>{a.product.locName}</Box>
                  {a.language.languageName !== 'English' && <Box>[{a.product.enName}]</Box>}
                </TableCell>
                <TableCell>{a.product.expansion}</TableCell>
                <TableCell>
                  {a.product.idGame !== 99 && (
                    <>
                      {a.product.expansionCode}-{a.product.nr}
                    </>
                  )}
                </TableCell>
                <TableCell>{a.product.rarity}</TableCell>
                <TableCell>
                  {a.isPlayset ? a.count * getPlaysetCoefficient(a.product.idGame) : a.count}
                </TableCell>
                <TableCell>
                  <Box display="flex">
                    <Flag
                      fallback={<span>Unknown</span>}
                      height="10px"
                      code={LANGUAGE_MAP_ICONS[a.language.idLanguage] || 'gg'}
                      style={{ alignSelf: 'center' }}
                    />
                    <Box padding="3px" />
                    <ConditionBadge condition={a.condition} />
                    {a.isFoil && (
                      <img
                        alt="foil"
                        height={iconHeight}
                        src={foilIcon}
                        style={{ alignSelf: 'center', marginLeft: '2px', marginRight: '2px' }}
                      />
                    )}
                    {a.isSigned && (
                      <img
                        alt="signed"
                        height={iconHeight}
                        src={signedIcon}
                        style={{ alignSelf: 'center', marginLeft: '2px', marginRight: '2px' }}
                      />
                    )}
                    {a.isFirstEd && (
                      <img
                        alt="firstEd"
                        height={iconHeight}
                        src={firstEdIcon}
                        style={{ alignSelf: 'center', marginLeft: '2px', marginRight: '2px' }}
                      />
                    )}
                    {a.isReverseHolo && (
                      <img
                        alt="reverseHolo"
                        height={iconHeight}
                        src={reverseHoloIcon}
                        style={{ alignSelf: 'center', marginLeft: '2px', marginRight: '2px' }}
                      />
                    )}
                  </Box>
                </TableCell>
                {/* <TableCell>{getPriceFormat(a.price, a.currencyCode)}</TableCell> */}
                <TableCell>{a.comments}</TableCell>
                <TableCell>
                  {a.issues?.length > 0 && (
                    <ColorBox
                      type="prop"
                      name="issues"
                      state="issues"
                      active
                      clickable
                      numIssues={a.issues?.length}
                      onClick={() => {
                        setSelectedArticle(a)
                        setOpenReportIssueDialog(true)
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
