import { Box, Typography } from '@material-ui/core'
import { ORDER_FILTERS, ORDER_PROPS, ORDER_STATES } from 'shared'

export interface ColorBoxProps {
  type: 'state' | 'filter' | 'prop'
  name: string
  state: string
  active?: boolean
  clickable?: boolean
  numIssues?: number
  onClick?: (state: string) => void
}

const getBoxConfigs = (type: string) => {
  if (type === 'filter') {
    return ORDER_FILTERS
  } else if (type === 'prop') {
    return ORDER_PROPS
  } else {
    return ORDER_STATES
  }
}

export const ColorBox = ({
  type,
  name,
  state,
  active,
  clickable,
  onClick,
  numIssues,
  ...props
}: ColorBoxProps): JSX.Element => {
  const boxConfigs = getBoxConfigs(type)

  const { label, boxColor, textColor } = boxConfigs.find((s) => s.name === name) || {
    label: state,
    boxColor: 'black',
    textColor: 'black',
  }

  const style = {
    border: '1px solid',
    borderColor: boxColor,
    backgroundColor: active ? boxColor : '',
    color: active ? textColor : boxColor,
    borderRadius: '5px',
    width: '85px',
    marginLeft: '3px',
    marginRight: '3px',
    cursor: clickable ? 'pointer' : '',
  }

  const getLabel = () => {
    let outputLabel = label
    if (numIssues) {
      outputLabel = `${numIssues} ${label.toLocaleUpperCase()}`
      if (numIssues === 1) {
        outputLabel = outputLabel.substring(0, outputLabel.length - 1)
      }
    }
    return outputLabel
  }

  return (
    <>
      <Box
        onClick={() => {
          clickable && onClick ? onClick(state) : null
        }}
        textAlign="center"
        style={style}
        {...props}
      >
        <Typography style={{ paddingTop: '2px' }}>{getLabel()}</Typography>
      </Box>
    </>
  )
}
