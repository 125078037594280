import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import { getPriceFormat } from 'lib/utils'
import { useTranslation } from 'react-i18next'
import { Order } from 'shared'

export interface OrderInfoProps {
  order: Order
}

export const OrderInfo = ({ order }: OrderInfoProps): JSX.Element => {
  const { t } = useTranslation()
  t

  const useStyles = makeStyles(() => ({
    info: {
      backgroundColor: '#b5bbdc',
      borderRadius: '10px',
      padding: '15px',
    },
  }))
  const styles = useStyles()

  return (
    <Box className={styles.info}>
      <Grid container>
        <Grid item xs={3}>
          <Typography variant="h6">Order summary</Typography>
          <Typography variant="body2">
            Total items: <b>{order.articleCount}</b>
          </Typography>
          <Typography variant="body2">
            Total value: <b>{getPriceFormat(order.totalValue, order.currencyCode)}</b>
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="h6">Buyer</Typography>
          <Typography variant="body2">{order.buyer.address.name}</Typography>
          <Typography variant="body2">[{order.buyer.username}]</Typography>
          <Typography variant="body2">{order.buyer.email}</Typography>
          <Typography variant="body2">{order.buyer.phone}</Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="h6">Shipping address</Typography>
          <Typography variant="body2">{order.shippingAddress?.name}</Typography>
          <Typography variant="body2">{order.shippingAddress?.extra}</Typography>
          <Typography variant="body2">{order.shippingAddress?.street}</Typography>
          <Typography variant="body2">
            {order.shippingAddress?.zip}, {order.shippingAddress?.city}
          </Typography>
          <Typography variant="body2">{order.shippingAddress?.country}</Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="h6">Shipping method</Typography>
          {order.shippingMethod?.name.replace('&lt;', '< ')}
          <Typography variant="body2">
            Is letter: {order.shippingMethod?.isLetter ? 'YES' : 'NO'}
          </Typography>
          <Typography variant="body2">
            Is insured: {order.shippingMethod?.isInsured ? 'YES' : 'NO'}
          </Typography>
          <Typography variant="body2">
            Tracking number: {order.trackingNumber ? order.trackingNumber : 'not available'}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
