import { Box, Checkbox, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core'
import {
  Archive,
  ArrowForwardIos,
  DescriptionOutlined,
  LinkOff,
  LocalOffer,
  LocalShipping,
  MailOutline,
  PlaylistAddCheck,
  Update,
} from '@material-ui/icons'
import { CustomTooltip, EditIcon, SealedIcon } from 'components/facets'
import NewWindow from 'components/facets/NewWindow'
import {
  changeArticleProp,
  changeOrderProp,
  removeOrderFromPickingList,
  setTrackingNumber,
} from 'lib/api'
import { useOrderStore } from 'lib/hooks'
import { getGameCodeFromName, getPickingId, getPickingList, getPickingProgress } from 'lib/utils'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutoSizer, Column, SortDirection, SortDirectionType, Table } from 'react-virtualized'
import Flag from 'react-world-flags'
import {
  GAME_NAMES,
  getOrderFromMkmOrder,
  getOrderIssues,
  getOrderState,
  hasIssues,
  hasUnresolvedIssues,
  Order,
  orderContainsSealed,
} from 'shared'
import { ArticlesTable } from './ArticlesTable'
import { ColorBox } from './ColorBox'
import { CompactOrdersTable } from './CompactOrdersTable'
import { OrderInfo } from './OrderInfo'
import { ReportIssueDialog } from './ReportIssueDialog'
import { RequestCancellationDialog } from './RequestCancellationDialog'
import { SolveIssueDialog } from './SolveIssueDialog'
import { TrackingNumberDialog } from './TrackingNumberDialog'
import { UnlinkOrderDialog } from './UnlinkOrderDialog'

export interface OrdersTableProps {
  handleUpdateOrders: (updatedOrders: Order[]) => void
  handleChangeOrderState: (orderIds: number[], newState: string) => void
}

export const OrdersTable = ({
  handleUpdateOrders,
  handleChangeOrderState,
}: OrdersTableProps): JSX.Element => {
  const { t } = useTranslation()

  const filteredOrders = useOrderStore((state) => state.filteredOrders)
  const filteredArticles = useOrderStore((state) => state.filteredArticles)
  // const view = useOrderStore((state) => state.view)
  const orders = useOrderStore((state) => state.orders)
  const articles = useOrderStore((state) => state.articles)
  // const filterByOrder = useOrderStore((state) => state.filterByOrder)
  // const layout = useOrderStore((state) => state.layout)
  const selectedArticle = useOrderStore((state) => state.selectedArticle)
  const selectedOrderIds = useOrderStore((state) => state.selectedOrderIds)
  const activeTab = useOrderStore((state) => state.activeTab)
  // const setFilterByOrder = useOrderStore((state) => state.setFilterByOrder)
  // const setOrders = useOrderStore((state) => state.setOrders)
  const setFilteredArticles = useOrderStore((state) => state.setFilteredArticles)
  // const setView = useOrderStore((state) => state.setView)
  const setSelectedArticle = useOrderStore((state) => state.setSelectedArticle)
  const setSelectedOrderIds = useOrderStore((state) => state.setSelectedOrderIds)
  const expandedOrder = useOrderStore((state) => state.expandedOrder)
  const setExpandedOrder = useOrderStore((state) => state.setExpandedOrder)
  const setExpandedRowIndex = useOrderStore((state) => state.setExpandedRowIndex)

  const [selectedOrder, setSelectedOrder] = useState<Order>()
  // const [openArticleDialog, setOpenArticleDialog] = useState<boolean>(false)
  const [openReportIssueDialog, setOpenReportIssueDialog] = useState<boolean>(false)
  const [openSolveIssueDialog, setOpenSolveIssueDialog] = useState<boolean>(false)
  const [openRequestCancellationDialog, setOpenRequestCancellationDialog] = useState<boolean>(false)
  const [openTrackingNumberDialog, setOpenTrackingNumberDialog] = useState<boolean>(false)
  const [editIssueMode, setEditIssueMode] = useState<boolean>(false)
  const [openUnlinkOrderDialog, setOpenUnlinkOrderDialog] = useState<boolean>(false)

  const [openMessagesPopup, setOpenMessagesPopup] = useState<boolean>(false)

  const useStyles = makeStyles(() => ({
    row: {
      borderBottom: 'solid 1px lightgrey',
    },
    iconButton: {
      alignSelf: 'center',
      cursor: 'pointer',
      paddingLeft: '5px',
    },
  }))
  const styles = useStyles()

  useEffect(() => {
    if (filteredArticles && filteredArticles.length > 0) {
      const idOrder = filteredArticles[0].idOrder
      const order = orders.find((o) => o.idOrder === idOrder)
      const newFilteredArticles = filteredArticles.map((oa) => {
        return order?.article.find((a) => a.idArticle === oa.idArticle) || oa
      })
      setFilteredArticles(newFilteredArticles)
    }
  }, [orders])

  useEffect(() => {
    setFilteredArticles(articles)
  }, [articles])

  // const handleRowClick = (order: Order) => {
  //   setFilterByOrder([order.idOrder])
  //   setView('article')
  // }

  // const handleUpdateOrders = (newOrder: Order) => {
  //   const hydratedOrder = hydrateOrder(newOrder, expansionMap, categoryMap)
  //   const idx = orders.findIndex((o) => o.idOrder === newOrder.idOrder)
  //   const newOrders = [...orders]
  //   newOrders[idx] = hydratedOrder
  //   setOrders(newOrders)
  // }

  const getFlagFromCountry = (country: string | undefined) => {
    if (country === 'D') {
      country = 'DE'
    }

    return (
      <Flag
        fallback={<span>Unknown</span>}
        height="10px"
        width="20px"
        code={country || 'gg'}
        style={{ alignSelf: 'center' }}
      />
    )
  }

  const getFormattedDate = (d: Date) => {
    if (d) {
      const localeDate = new Date(d).toLocaleString()
      const [date, time] = localeDate.split(', ')
      return (
        <>
          <div>{date}</div>
          <div>{time}</div>
        </>
      )
    } else return <></>
  }

  const handleCloseReportIssueDialog = () => {
    setSelectedOrder(undefined)
    setSelectedArticle(undefined)
    setEditIssueMode(false)
    setOpenReportIssueDialog(false)
  }

  const handleCloseRequestCancellationDialog = () => {
    setSelectedOrder(undefined)
    setOpenRequestCancellationDialog(false)
  }

  const handleConfirmReportIssueDialog = async (issue: string) => {
    if (selectedArticle) {
      const newIssues = [...(selectedArticle.issues || [])]
      newIssues.push({
        description: issue,
        solved: false,
        solution: '',
      })
      const mkmOrder = await changeArticleProp(
        selectedArticle.idOrder,
        selectedArticle.idArticle,
        'issues',
        newIssues
      )
      const newOrder = getOrderFromMkmOrder(mkmOrder)
      handleUpdateOrders([newOrder])
    } else if (selectedOrder) {
      // only one issue per order, for now
      // const newIssues = [...(selectedOrder.pt.issues || [])]
      const newIssues = []
      newIssues.push({
        description: issue,
        solved: false,
        solution: '',
      })
      const mkmOrder = await changeOrderProp(selectedOrder.idOrder, 'issues', newIssues)
      const newOrder = getOrderFromMkmOrder(mkmOrder)
      handleUpdateOrders([newOrder])
    }
    handleCloseReportIssueDialog()
  }

  const handleCloseSolveIssueDialog = () => {
    setSelectedOrder(undefined)
    setOpenSolveIssueDialog(false)
  }

  const handleConfirmSolveIssueDialog = async (solution: string) => {
    if (selectedOrder) {
      const issues = getOrderIssues(selectedOrder)
      if (issues.length === 0) {
        issues[0] = {
          description: '',
          solved: true,
          solution: solution,
        }
      } else {
        issues[0].solved = true
        issues[0].solution = solution
      }
      const mkmOrder = await changeOrderProp(selectedOrder.idOrder, 'issues', issues)
      const newOrder = getOrderFromMkmOrder(mkmOrder)
      handleUpdateOrders([newOrder])
    }
    if (selectedArticle) {
      const mkmOrder = await changeArticleProp(
        selectedArticle.idOrder,
        selectedArticle.idArticle,
        'solution',
        solution
      )
      const newOrder = getOrderFromMkmOrder(mkmOrder)
      handleUpdateOrders([newOrder])
    }
    handleCloseSolveIssueDialog()
  }

  const handleConfirmTrackingNumberDialog = async (trackingNumber: string) => {
    if (selectedOrder) {
      await setTrackingNumber(selectedOrder?.idOrder, trackingNumber)
      const mkmOrder = await changeOrderProp(
        selectedOrder?.idOrder,
        'trackingNumber',
        trackingNumber
      )
      const newOrder = getOrderFromMkmOrder(mkmOrder)
      handleUpdateOrders([newOrder])
    }
    handleCloseTrackingNumberDialog()
  }

  const handleCloseTrackingNumberDialog = () => {
    setSelectedOrder(undefined)
    setOpenTrackingNumberDialog(false)
  }

  const handleConfirmRequestCancellationDialog = async (reason: string, relistItems: boolean) => {
    console.log(reason)
    console.log(relistItems)
    handleCloseRequestCancellationDialog()
  }

  const handleCloseUnlinkOrderDialog = () => {
    setSelectedOrder(undefined)
    setOpenUnlinkOrderDialog(false)
  }

  const handleConfirmUnlinkOrderDialog = async () => {
    if (!selectedOrder) return
    await handleRemoveOrderFromPickingList(selectedOrder)
    handleCloseUnlinkOrderDialog()
  }

  const handleSelectRow = (e: any, idOrder: number) => {
    if (e.target.checked) {
      const newSelectedOrderIds = [...selectedOrderIds]
      newSelectedOrderIds.push(idOrder)
      setSelectedOrderIds(newSelectedOrderIds)
    } else {
      if (selectedOrderIds.includes(idOrder)) {
        const idx = selectedOrderIds.findIndex((id) => id === idOrder)
        const newSelectedOrderIds = [...selectedOrderIds]
        newSelectedOrderIds.splice(idx, 1)
        setSelectedOrderIds(newSelectedOrderIds)
      }
    }
  }

  const handleOpenMessagesPopup = () => {
    setOpenMessagesPopup(false)
    setTimeout(() => setOpenMessagesPopup(true), 200)
  }

  const handleExpandRow = (order: Order, rowIndex: number) => {
    setExpandedOrder(order)
    setExpandedRowIndex(rowIndex)
  }

  const getArticleIssues = (order: Order) => {
    const articleIssues: string[] = []
    order?.pt?.article?.forEach((pta) => {
      const article = order.article.find((a) => a.idArticle === pta.idArticle)
      pta.issues?.forEach((i) =>
        articleIssues.push(`${article ? article.product.enName : ''}: ${i.description}`)
      )
    })
    return articleIssues
  }

  const showPickingProgress = (order: Order) => {
    const pickingProgress = getPickingProgress(order)
    if (pickingProgress === -1) {
      return '-'
    } else {
      return `${pickingProgress}%`
    }
  }

  // const cache = new CellMeasurerCache({
  //   fixedWidth: true,
  //   minHeight: 40,
  // })

  // const cellRendererMeasurer = (
  //   parent: MeasuredCellParent,
  //   rowIndex: number | undefined,
  //   content: ReactElement
  // ) => {
  //   return (
  //     <CellMeasurer cache={cache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
  //       <div
  //         style={{
  //           whiteSpace: 'normal',
  //         }}
  //       >
  //         {content}
  //       </div>
  //     </CellMeasurer>
  //   )
  // }

  // columns sorting
  const [sortBy, setSortBy] = useState<string>('datePaid')
  const [sortDirection, setSortDirection] = useState<SortDirectionType>(SortDirection.DESC)
  const sort = ({ sortBy }: { sortBy: string }) => {
    setSortBy(sortBy)
    setSortDirection(sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC)
    let sortedOrders = [...filteredOrders]

    if (sortBy === 'datePaid') {
      sortedOrders = filteredOrders.sort((a, b) => {
        return a.state.datePaid < b.state.datePaid ? 1 : -1
      })
    } else if (sortBy === 'dateSent') {
      sortedOrders = filteredOrders.sort((a, b) => {
        return a.state.dateSent < b.state.dateSent ? 1 : -1
      })
    } else if (sortBy === 'buyer') {
      sortedOrders = filteredOrders.sort((a, b) => {
        return a.buyer.username < b.buyer.username ? 1 : -1
      })
    } else if (sortBy === 'list') {
      sortedOrders = filteredOrders.sort((a, b) => {
        return getPickingList(a) < getPickingList(b) ? 1 : -1
      })
    } else if (sortBy === 'pid') {
      sortedOrders = filteredOrders.sort((a, b) => {
        return getPickingId(a) < getPickingId(b) ? 1 : -1
      })
    } else if (sortBy === 'progress') {
      sortedOrders = filteredOrders.sort((a, b) => {
        return getPickingProgress(a) < getPickingProgress(b) ? 1 : -1
      })
    } else if (sortBy === 'shippingMethod') {
      sortedOrders = filteredOrders.sort((a, b) => {
        return a.shippingMethod.name < b.shippingMethod.name ? 1 : -1
      })
    }

    if (sortDirection === SortDirection.DESC) {
      sortedOrders = sortedOrders.reverse()
    }
    return sortedOrders
  }

  const handleRemoveOrderFromPickingList = async (order: Order) => {
    const mkmOrder = await removeOrderFromPickingList(order.pt.picking.pickingList, order.idOrder)
    const updatedOrder = getOrderFromMkmOrder(mkmOrder)
    handleUpdateOrders([updatedOrder])
  }

  return (
    <>
      {expandedOrder ? (
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <CompactOrdersTable handleSelectRow={handleSelectRow} />
          </Grid>
          <Grid item xs={7}>
            <OrderInfo order={expandedOrder} />
            <Box padding={1} />
            <ArticlesTable
              articles={expandedOrder.article}
              setSelectedArticle={setSelectedArticle}
              setOpenReportIssueDialog={setOpenReportIssueDialog}
            />
          </Grid>
        </Grid>
      ) : (
        <AutoSizer disableHeight>
          {({ width }) => (
            <Table
              // deferredMeasurementCache={cache}
              width={width}
              height={700}
              headerHeight={30}
              rowHeight={40}
              rowCount={filteredOrders.length}
              rowGetter={({ index }) => filteredOrders[index]}
              rowClassName={styles.row}
              sort={sort}
              sortBy={sortBy}
              sortDirection={sortDirection}
            >
              {activeTab === 1 && (
                <Column
                  width={70}
                  label="Select"
                  dataKey=""
                  disableSort={true}
                  cellRenderer={({ rowData: order }) => (
                    <Checkbox
                      checked={selectedOrderIds.includes(order.idOrder)}
                      onClick={(e: any) => handleSelectRow(e, order.idOrder)}
                    />
                  )}
                />
              )}

              <Column
                width={100}
                label="Paid date"
                dataKey="datePaid"
                cellRenderer={({ rowData: order }) => getFormattedDate(order.state.datePaid)}
              />

              <Column
                width={100}
                label="Sent date"
                dataKey="dateSent"
                cellRenderer={({ rowData: order }) => getFormattedDate(order.state.dateSent)}
              />

              <Column
                width={150}
                label="Buyer"
                dataKey="buyer"
                cellRenderer={({ rowData: order }) => (
                  <>
                    <Box display="flex">
                      {getFlagFromCountry(order.shippingAddress?.country)}
                      <Box paddingLeft={1}>
                        <Box>{order.buyer.username}</Box>
                        <Box>{order.buyer.address.name}</Box>
                      </Box>
                    </Box>
                  </>
                )}
              />

              <Column
                width={25}
                label=""
                dataKey=""
                disableSort={true}
                cellRenderer={({ rowData: order }) => (
                  <>
                    <Tooltip title={order.pt.articleGames}>
                      <span
                        className={`ss-${getGameCodeFromName(
                          order.pt.articleGames
                        )} ss-game-icon ss-fw`}
                        style={{ fontSize: '1.5rem' }}
                      ></span>
                    </Tooltip>
                  </>
                )}
              />

              <Column
                width={25}
                label=""
                dataKey=""
                disableSort={true}
                cellRenderer={({ rowData: order }) => (
                  <>
                    {orderContainsSealed(order) && (
                      <Tooltip title="Contains sealed">
                        <span>
                          <SealedIcon style={{ verticalAlign: 'middle' }} />
                        </span>
                      </Tooltip>
                    )}
                  </>
                )}
              />

              <Column
                width={25}
                label=""
                dataKey=""
                disableSort={true}
                cellRenderer={({ rowData: order }) => (
                  <>
                    {order.isPresale && (
                      <Tooltip title="Presale">
                        <Update style={{ verticalAlign: 'middle' }} />
                      </Tooltip>
                    )}
                  </>
                )}
              />

              {activeTab === 3 && (
                <Column
                  width={200}
                  label="Shipping method"
                  dataKey="shippingMethod"
                  cellRenderer={({ rowData: order }) => <>{order.shippingMethod.name}</>}
                />
              )}

              <Column
                width={100}
                label="Status"
                dataKey=""
                disableSort={true}
                cellRenderer={({ rowData: order }) => (
                  <ColorBox
                    type="state"
                    name={getOrderState(order)}
                    state={getOrderState(order)}
                    active={true}
                  ></ColorBox>
                )}
              />

              {/* <Column
                width={150}
                label="Order note"
                dataKey=""
                cellRenderer={({ rowData: order, parent, rowIndex }) =>
                  cellRendererMeasurer(parent, rowIndex, <>{order.note}</>)
                }
              /> */}

              <Column
                width={100}
                label="Issues"
                dataKey=""
                disableSort={true}
                cellRenderer={({ rowData: order }) => (
                  <Box display="flex" alignItems="center">
                    {hasIssues(order) && (
                      <ColorBox
                        type="prop"
                        name="issues"
                        state="issues"
                        active
                        clickable
                        numIssues={getOrderIssues(order).length + getArticleIssues(order).length}
                        onClick={() => {
                          setSelectedOrder(order)
                          setOpenReportIssueDialog(true)
                        }}
                      />
                    )}
                  </Box>
                )}
              />

              <Column
                width={120}
                label="Actions"
                dataKey=""
                disableSort={true}
                cellRenderer={({ rowData: order }) => (
                  <Box display="flex" alignItems="center">
                    {[0].includes(activeTab) && (
                      <Tooltip title="Message buyer">
                        <Box
                          onClick={() => {
                            setSelectedOrder(order)
                            handleOpenMessagesPopup()
                          }}
                          alignSelf="center"
                          style={{ cursor: 'pointer' }}
                        >
                          <MailOutline />
                        </Box>
                      </Tooltip>
                    )}

                    <CustomTooltip
                      title={
                        hasIssues(order)
                          ? (t('orders.editOrderIssue') as string)
                          : (t('orders.addOrderIssue') as string)
                      }
                    >
                      <Box
                        onClick={() => {
                          setSelectedOrder(order)
                          setEditIssueMode(getOrderIssues(order).length > 0)
                          setOpenReportIssueDialog(true)
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {hasIssues(order) ? <EditIcon /> : <DescriptionOutlined />}
                      </Box>
                    </CustomTooltip>
                    {hasUnresolvedIssues(order) && (
                      <>
                        <Tooltip title="Solve all">
                          <Box
                            onClick={() => {
                              setSelectedOrder(order)
                              setOpenSolveIssueDialog(true)
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <PlaylistAddCheck />
                          </Box>
                        </Tooltip>
                      </>
                    )}

                    {getOrderState(order) === 'picking' && (
                      <CustomTooltip title={t('orders.unlinkFromPickingList') as string}>
                        <Box
                          onClick={() => {
                            setSelectedOrder(order)
                            setOpenUnlinkOrderDialog(true)
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <LinkOff />
                        </Box>
                      </CustomTooltip>
                    )}

                    {[3].includes(activeTab) && (
                      <>
                        <CustomTooltip title="Tracking number">
                          <Box
                            className={styles.iconButton}
                            onClick={() => {
                              setSelectedOrder(order)
                              setOpenTrackingNumberDialog(true)
                            }}
                          >
                            <LocalOffer />
                          </Box>
                        </CustomTooltip>

                        {getOrderState(order) === 'picked' && (
                          <CustomTooltip title="Mark as packed">
                            <Box
                              className={styles.iconButton}
                              onClick={() => {
                                handleChangeOrderState([order.idOrder], 'packed')
                              }}
                            >
                              <Archive />
                            </Box>
                          </CustomTooltip>
                        )}

                        {getOrderState(order) === 'packed' && (
                          <CustomTooltip title="Mark as sent">
                            <Box
                              className={styles.iconButton}
                              onClick={() => {
                                handleChangeOrderState([order.idOrder], 'sent')
                              }}
                            >
                              <LocalShipping />
                            </Box>
                          </CustomTooltip>
                        )}
                      </>
                    )}
                  </Box>
                )}
              />

              {activeTab === 1 && (
                <Column
                  width={50}
                  label="List"
                  dataKey="list"
                  cellRenderer={({ rowData: order }) => (
                    <>
                      {order.pt.picking && order.pt.picking.pickingList
                        ? order.pt.picking.pickingList
                        : '-'}
                    </>
                  )}
                />
              )}

              {[1, 3].includes(activeTab) && (
                <Column
                  width={50}
                  label="PID"
                  dataKey="pid"
                  cellRenderer={({ rowData: order }) => (
                    <>
                      {order.pt.picking && order.pt.picking.pickingId
                        ? order.pt.picking.pickingId
                        : '-'}
                    </>
                  )}
                />
              )}

              {[1].includes(activeTab) && (
                <Column
                  width={100}
                  label="Progress"
                  dataKey="progress"
                  cellRenderer={({ rowData: order }) => <>{showPickingProgress(order)}</>}
                />
              )}

              <Column
                width={100}
                label=""
                dataKey=""
                disableSort={true}
                cellRenderer={({ rowData: order, rowIndex }) => (
                  <Box
                    onClick={() => handleExpandRow(order, rowIndex)}
                    style={{ cursor: 'pointer' }}
                  >
                    <Box display="flex" alignItems="end">
                      <Typography style={{ fontSize: '0.65rem' }}>{order.idOrder}</Typography>
                      <ArrowForwardIos fontSize="small" style={{ marginLeft: '10px' }} />
                    </Box>
                  </Box>
                )}
              />
            </Table>
          )}
        </AutoSizer>
      )}

      <ReportIssueDialog
        open={openReportIssueDialog}
        onClose={handleCloseReportIssueDialog}
        onConfirm={handleConfirmReportIssueDialog}
        type={selectedArticle ? 'article' : 'order'}
        order={selectedOrder}
        editMode={editIssueMode}
      />

      <SolveIssueDialog
        open={openSolveIssueDialog}
        onClose={handleCloseSolveIssueDialog}
        onConfirm={handleConfirmSolveIssueDialog}
      />

      <RequestCancellationDialog
        order={selectedOrder}
        open={openRequestCancellationDialog}
        onClose={handleCloseRequestCancellationDialog}
        onConfirm={handleConfirmRequestCancellationDialog}
      />

      {selectedOrder && (
        <TrackingNumberDialog
          order={selectedOrder}
          open={openTrackingNumberDialog}
          onClose={handleCloseTrackingNumberDialog}
          onConfirm={handleConfirmTrackingNumberDialog}
        />
      )}

      {selectedOrder && (
        <UnlinkOrderDialog
          open={openUnlinkOrderDialog}
          order={selectedOrder}
          onClose={handleCloseUnlinkOrderDialog}
          onConfirm={handleConfirmUnlinkOrderDialog}
        />
      )}

      {openMessagesPopup ? (
        <NewWindow
          url={(function () {
            const url =
              `https://cardmarket.com/en/${
                selectedOrder ? GAME_NAMES[selectedOrder?.article[0].product.idGame] : ''
              }/Account/Messages/${selectedOrder?.buyer.username}` || ''
            return url
          })()}
        />
      ) : null}
    </>
  )
}
