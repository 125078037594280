import { Box, Grid } from '@material-ui/core'
import { Condition } from 'components/facets'
import Flag from 'react-world-flags'
import { OrderArticle } from 'shared'
import { LANGUAGE_MAP_ICONS } from 'utils/constants'
import { CardInfoBox } from './CardInfoBox'
import { PickingButton } from './PickingButton'

export interface OrderVisualArticleProps {
  article: OrderArticle
  fullScreen?: boolean
  hideBar?: boolean
  hideButtons?: boolean
  handlePick?: (article: OrderArticle, quantity: number) => void
  handleCompletePicking?: (article: OrderArticle, quantity: number) => void
  handleReportIssue?: (article: OrderArticle) => void
  handleBackToPicking?: (article: OrderArticle) => void
}

export const OrderVisualArticle = ({
  article,
  fullScreen = false,
  hideBar = false,
  hideButtons = false,
  handlePick,
  handleCompletePicking,
  handleReportIssue,
  handleBackToPicking,
}: OrderVisualArticleProps): JSX.Element => {
  const articleWidth = fullScreen ? '25vw' : 212
  const articleHeight = fullScreen ? `${0.5 * 80}vw` : 310
  const imgSrc = `https://mtgpowertools.s3.eu-central-1.amazonaws.com/images/mtg/${article.idProduct}.jpg`
  const barWidth = 55

  return (
    <>
      <Box width={articleWidth} style={{ float: fullScreen ? 'right' : 'left' }}>
        {!hideButtons && (
          <Grid container>
            <Grid item xs={12}>
              <PickingButton
                type="reportIssue"
                article={article}
                handleReportIssue={handleReportIssue}
                fullWidth
              />
            </Grid>
          </Grid>
        )}

        <Box display="flex" width="100%" paddingY={1}>
          <img src={imgSrc} width="100%" height={fullScreen ? undefined : articleHeight} />
          {!hideBar && (
            <Box
              style={{
                backgroundColor: '#00000099',
                position: 'absolute',
                color: 'white',
                width: barWidth + 'px',
                height: articleHeight,
                fontSize: '1.3rem',
                paddingTop: '35px',
                paddingLeft: '5px',
                paddingRight: '5px',
                textAlign: 'center',
                marginLeft:
                  typeof articleWidth === 'number' ? articleWidth - barWidth + 'px' : '0px',
              }}
            >
              <Box>{article.product.expansionCode}</Box>
              <Box>{article.count}x</Box>
              <Condition value={article.condition} />
              <Box>
                <Flag
                  fallback={<span>Unknown</span>}
                  code={LANGUAGE_MAP_ICONS[article.language.idLanguage] || 'gg'}
                  height={16}
                />
              </Box>
            </Box>
          )}
        </Box>

        {!hideButtons && (
          <>
            <Grid container>
              <Grid item xs={12}>
                <PickingButton type="pick" article={article} handlePick={handlePick} fullWidth />
              </Grid>
            </Grid>

            <Box padding="5px"></Box>

            <Grid container>
              <Grid item xs={12}>
                <PickingButton
                  type="put"
                  article={article}
                  handleCompletePicking={handleCompletePicking}
                  fullWidth
                />
              </Grid>
            </Grid>
          </>
        )}

        {hideButtons && !fullScreen && (
          <PickingButton
            type="backToPicking"
            article={article}
            handleBackToPicking={handleBackToPicking}
            fullWidth
          />
        )}

        {!fullScreen && (
          <>
            <Box padding="5px"></Box>

            <CardInfoBox article={article} transparent={hideButtons} />
          </>
        )}
      </Box>
    </>
  )
}
