import { Backdrop, Box, CircularProgress, makeStyles, useTheme } from '@material-ui/core'
import { useUser } from 'components/providers/UserProvider'
import { cardDataDB } from 'config/localforage'
import {
  changeOrdersState,
  createPTOrders,
  fetchOrders,
  getPickingListsInfo,
  resetPTOrders,
} from 'lib/api'
import { useOrderStore } from 'lib/hooks'
import { useEffect } from 'react'
import {
  getOrderFromMkmOrder,
  hydrateOrder,
  hydrateOrderArticles,
  Order,
  OrderArticle,
  ORDER_SIDEBAR_TABS,
} from 'shared'
import { CardmarketSalesPresenter } from './CardmarketSalesPresenter'
import { OrdersTable } from './OrdersTable'
import { OrdersToolbar } from './OrdersToolbar'
import { OrdersWelcome } from './OrdersWelcome'
import { PickingListPresenter } from './PickingListPresenter'
import { PickingPresenter } from './PickingPresenter'
import { Sidebar } from './Sidebar'

export const OrdersPresenter = (): JSX.Element => {
  const { user } = useUser()
  const theme = useTheme()
  const orders = useOrderStore((state) => state.orders)
  const rawOrders = useOrderStore((state) => state.rawOrders)
  const activeTab = useOrderStore((state) => state.activeTab)
  const activePickingList = useOrderStore((state) => state.activePickingList)
  const expansionMap = useOrderStore((state) => state.expansionMap)
  const categoryMap = useOrderStore((state) => state.categoryMap)
  const loadingFromPt = useOrderStore((state) => state.loadingFromPt)
  const setOrders = useOrderStore((state) => state.setOrders)
  const setRawOrders = useOrderStore((state) => state.setRawOrders)
  const setArticles = useOrderStore((state) => state.setArticles)
  const setExpansionMap = useOrderStore((state) => state.setExpansionMap)
  const setCategoryMap = useOrderStore((state) => state.setCategoryMap)
  const setLoadingFromMkm = useOrderStore((state) => state.setLoadingFromMkm)
  const setLoadingFromPt = useOrderStore((state) => state.setLoadingFromPt)

  const SIDEBAR_WIDTH = '180px'

  const useStyles = makeStyles(() => ({
    sidebar: {
      backgroundColor: '#ececee',
      minWidth: SIDEBAR_WIDTH,
      maxWidth: SIDEBAR_WIDTH,
    },
  }))
  const styles = useStyles()

  useEffect(() => {
    const newArticles: OrderArticle[] = []
    orders.forEach((o) => {
      newArticles.push(...o.article)
    })
    setArticles(newArticles)
  }, [orders])

  const initializeOrderStore = (data: Order[]) => {
    const articles: OrderArticle[] = []
    console.log(`hydrating orders and articles...`)
    data.forEach((order) => {
      const { orderArticles, articleCategories, articleGames } = hydrateOrderArticles(
        order,
        expansionMap,
        categoryMap
      )
      articles.push(...orderArticles)
      order.pt.articleCategories = Array.from(articleCategories)
      order.pt.articleGames = Array.from(articleGames)
    })
    console.log(`hydrated ${data.length} orders`)
    setOrders(data)
    setLoadingFromMkm(false)
    setLoadingFromPt(false)
  }

  useEffect(() => {
    if (
      Object.keys(expansionMap).length > 0 &&
      Object.keys(categoryMap).length > 0 &&
      rawOrders.length > 0
    ) {
      initializeOrderStore(rawOrders)
    }
  }, [expansionMap, categoryMap, rawOrders])

  const tmpExpansionMap: {
    [key: string]: {
      code: string
      releaseDate: Date
    }
  } = {}
  const tmpCategoryMap: {
    [key: number]: {
      category: string
      colors: string
    }
  } = {}

  const initializeMapsAndOrderStore = (data: Order[]) => {
    cardDataDB
      .iterate((val: any) => {
        if (val.expansions) {
          for (const exp of val.expansions) {
            tmpExpansionMap[exp.name as string] = { code: exp.code, releaseDate: exp.releaseDate }
          }
        }

        if (val.cardData) {
          for (const card of val.cardData) {
            tmpCategoryMap[card.attributes[0]] = {
              category: card.attributes[8],
              colors: card.attributes[10],
            }
          }
        }
      })
      .then(() => {
        setExpansionMap(tmpExpansionMap)
        setCategoryMap(tmpCategoryMap)
        setRawOrders(data)
      })
  }

  useEffect(() => {
    if (cardDataDB) {
      console.log('fetching orders...')
      const start = Date.now()
      setLoadingFromPt(true)
      fetchOrders().then((data) => {
        const end = Date.now()
        console.log(`fetched ${data.length} orders in ${end - start} ms`)
        if (data.length > 0) {
          initializeMapsAndOrderStore(data)
        } else {
          setLoadingFromPt(false)
        }
      })
    }
  }, [cardDataDB])

  const handleChangeOrderState = async (orderIds: number[], newState: string) => {
    const updatedOrders: Order[] = []
    let mkmOrders: Order[] = []
    if (newState === 'paid') {
      mkmOrders = await resetPTOrders(orderIds)
    } else if (newState === 'picking') {
      mkmOrders = await createPTOrders(orderIds, newState)
    } else {
      mkmOrders = await changeOrdersState(orderIds, newState)
    }
    const pickingListsInfo = await getPickingListsInfo(user.cardmarketUsername, orderIds)
    for (const mkmOrder of mkmOrders) {
      const newOrder = getOrderFromMkmOrder(mkmOrder)

      const pickingList = pickingListsInfo.find((pl) =>
        pl.orders.find((o) => o.orderId === newOrder.idOrder)
      )
      const pickingId = pickingList?.orders.find((o) => o.orderId === newOrder.idOrder)?.pickingId

      newOrder.pt.picking = {
        pickingList: pickingList?.name || '',
        pickingId: pickingId || '',
        numArticles: pickingList?.numArticles || 0,
        numPickedArticles: pickingList?.numPickedArticles || 0,
      }
      updatedOrders.push(newOrder)
    }

    handleUpdateOrders(updatedOrders)
  }

  const handleUpdateOrders = (updatedOrders: Order[]) => {
    const newOrders = [...orders]
    updatedOrders.forEach((uo) => {
      const hydratedOrder = hydrateOrder(uo, expansionMap, categoryMap)
      const idx = orders.findIndex((o) => o.idOrder === uo.idOrder)
      newOrders[idx] = hydratedOrder
    })
    setOrders(newOrders)
  }

  const presenterWidth = '80vw'

  return (
    <>
      {loadingFromPt && (
        <Backdrop
          open={loadingFromPt}
          style={{ color: theme.palette.lightGray, zIndex: theme.zIndex.modal }}
        >
          <CircularProgress />
        </Backdrop>
      )}
      <Box display="flex">
        <Box className={styles.sidebar}>
          <Sidebar />
        </Box>
        <Box
          padding={1}
          minWidth={presenterWidth}
          maxWidth={presenterWidth}
          data-testid="orders-presenter"
        >
          <Box hidden={activeTab !== -1} data-testid="orders-welcome-container">
            <OrdersWelcome />
          </Box>
          <Box
            hidden={activeTab !== 2 || !!activePickingList}
            data-testid="picking-list-presenter-container"
          >
            <PickingListPresenter handleChangeOrderState={handleChangeOrderState} />
          </Box>
          {activeTab === 2 && activePickingList && (
            <PickingPresenter handleChangeOrderState={handleChangeOrderState} />
          )}
          <Box hidden={[-1, 2, ORDER_SIDEBAR_TABS.CARDMARKET_SALES].includes(activeTab)}>
            <OrdersToolbar handleChangeOrderState={handleChangeOrderState} />
            <Box padding={2}></Box>
            <OrdersTable
              handleUpdateOrders={handleUpdateOrders}
              handleChangeOrderState={handleChangeOrderState}
            />
          </Box>
          {/* cardmarket sales page */}
          <Box hidden={activeTab !== ORDER_SIDEBAR_TABS.CARDMARKET_SALES}>
            <CardmarketSalesPresenter />
          </Box>
        </Box>
      </Box>
    </>
  )
}
